interface AppState {
  cmsToken: string | null
  user: User | null
}

export interface User extends UserCreate {
  id: number
}

export interface UserCreate extends UserUpdate {
  email: string
  password: string
  confirmPassword: string
  firstname: string
  lastname: string
  role: string
  branch: string
  points: number
  glory: number
  height: number
  weight: number
  dateOfBirth: string
  trialExpiration?: string
  healthPlanPath?: string
}

export interface UserUpdate {
  dailyTarget: number | string
}

export const useAuthenticationStore = defineStore('authentication', {
  /**
   * Represents the state of the application.
   */
  state: (): AppState => ({
    cmsToken: null,
    user: null,
  }),
  getters: {
    isAuthenticated(state: AppState): boolean {
      return state.cmsToken !== null && state.user !== null
    },
    fullName(state: AppState): string {
      return [state.user?.firstname, state.user?.lastname].join(' ')
    },
  },
  actions: {
    async createToken({ email, password }: { email: string, password: string }) {
      const result = await useNuxtApp().$cmsFetch('/user/token/create', {
        method: 'POST',
        body: {
          email,
          password,
        },
      })
      this.cmsToken = result.token
      this.user = result.user
    },
    verifyToken({ token }: { token: string }) {
      return useNuxtApp().$cmsFetch('/user/token/verify', {
        method: 'POST',
        body: {
          token,
        },
      })
    },
    logout() {
      this.cmsToken = null
      this.user = null
    },

    createUser(config: UserCreate) {
      const result = useNuxtApp().$cmsFetch('/user', {
        method: 'POST',
        body: {
          ...config,
          trial: true,
        },
      })
      this.cmsToken = result?.token
      this.user = result?.user
    },
    updateUser(config: UserUpdate) {
      return useNuxtApp().$cmsFetch('/user/token/create', {
        method: 'PUT',
        body: {
          ...config,
        },
      })
    },
  },
  persist: true,
})
