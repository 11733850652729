import { consola } from 'consola'
import { ofetch } from 'ofetch'

export default defineNuxtPlugin({
  name: 'network',
  async setup(nuxtApp) {
    const { cmsToken } = storeToRefs(useAuthenticationStore())

    const cmsClient = ofetch.create({
      baseURL: nuxtApp.$config.public.CMS_URL,
      headers: {
        'Accept': 'application/json',
        'x-api-key': nuxtApp.$config.public.CMS_API_KEY as string,
      },
      retry: 3,
      onRequest({ options }) {
        options.headers = {
          ...(options.headers || {}),
          'Accept': 'application/json',
          'x-api-key': nuxtApp.$config.public.CMS_API_KEY,
        }
        if (cmsToken.value) {
          options.headers = {
            ...(options.headers || {}),
            Authorization: `Bearer ${cmsToken.value}`,
          }
        }
      },
      onRequestError({ request, error }) {
        // Log error
        consola.error('[fetch request error]', request, error)
      },
      onResponseError({ request, response }) {
        // Log error
        consola.error('[fetch response error]', request, response.status, response.body)
      },
    })
    return {
      provide: {
        cmsFetch: cmsClient,
      },
    }
  },
})
