// Generated by @vite-pwa/nuxt
import { defineNuxtPlugin } from '#imports'
import { pwaAssetsIcons } from 'virtual:pwa-assets/icons'
import type { PWAAssetIcon, PWAIcons } from '#build/pwa-icons/pwa-icons'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      pwaIcons: {
        transparent: configureEntry('transparent'),
        maskable: configureEntry('maskable'),
        favicon: configureEntry('favicon'),
        apple: configureEntry('apple'),
        appleSplashScreen: configureEntry('appleSplashScreen')
      } satisfies PWAIcons
    }
  }
})

function configureEntry<K extends keyof PWAIcons>(key: K) {
  return Object.values(pwaAssetsIcons[key] ?? {}).reduce((acc, icon) => {
    const entry: PWAAssetIcon<any> = {
      ...icon,
      asImage: {
        src: icon.url,
        key: `${key}-${icon.name}`
      }
    }
    if (icon.width && icon.height) {
      entry.asImage.width = icon.width
      entry.asImage.height = icon.height
    }
    ;(acc as unknown as any)[icon.name] = entry
    return acc
  }, {} as PWAIcons[typeof key])
}
