import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_935jDikbHulgMeta } from "/workspace/pages/levels/[id].vue?macro=true";
import { default as _91video_93bKJS5rReNmMeta } from "/workspace/pages/levels/[video].vue?macro=true";
import { default as indexlmy6pe4wSEMeta } from "/workspace/pages/levels/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as registrationzJtdLNH9pfMeta } from "/workspace/pages/registration.vue?macro=true";
export default [
  {
    name: "index___cs",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "levels-id___cs",
    path: "/urovne/:id()",
    component: () => import("/workspace/pages/levels/[id].vue")
  },
  {
    name: "levels-video___cs",
    path: "/urovne/:id()/:video()",
    component: () => import("/workspace/pages/levels/[video].vue")
  },
  {
    name: "levels___cs",
    path: "/urovne",
    component: () => import("/workspace/pages/levels/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginGeKhs9qf7uMeta || {},
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "registration___cs",
    path: "/registrace",
    meta: registrationzJtdLNH9pfMeta || {},
    component: () => import("/workspace/pages/registration.vue")
  }
]